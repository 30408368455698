import { Col } from "react-bootstrap";
import ReactTableBase from "../../../src/shared/components/table/ReactTableBase";
import styled from "styled-components";
import { CustomSelect } from "../../shared/components/CustomSelect";
import { Button } from "../../shared/components/Button";
import { useEffect, useState } from "react";
import {
  chainIdToName,
  getStatusBadge,
  historyOption,
  optionOrderStatus,
  tableConfig,
} from "../../redux/constants/constans";
import {
  approveWithdraw,
  blockUnblockWithdraw,
  getBlockUnblockWithdrawStatus,
  getManulWithdrawStatus,
  getToken,
  getWithdrawLimit,
  getWithdrawRequest,
  manulWithdrawRequestUpdate,
  rejectWithdraw,
} from "../../utils/services/user.service";
import moment from "moment";
import numeral from "numeral";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { Ring } from "@uiball/loaders";
import Tooltip from "@/shared/components/Tooltip";
import WithdrawLimitModal from "./AddSafebox/withdrawLimitModal";
import WithdrawReportDetailsModal from "./WithdrawReportDetailsModal";

const WithdrawReport = () => {
  const [selectedOrderStatus, setSelectedOrderStatus] = useState("");
  const [searchName, setSearchName] = useState("");
  const [searchEmail, setSearchEmail] = useState("");
  const [searchActivity, setSearchActivity] = useState("virtual");
  const [searchSymbol, setSearchSymbol] = useState("");
  const [allSymbols, setAllSymbols] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentStatus, setCurrentStatus] = useState("");
  const [currentManualWithdrawStatus, setCurrentManualWithdrawStatus] = useState("");
  const [limitVal, setLimitVal] = useState("");
  const [reFetch, setRefetch] = useState(false);
  const [limitType, setLimitType] = useState("");
  const [modalLimit, setModalLimit] = useState(false);
  const [showReportDetails, setShowReportDetails] = useState(false);
  const [reportDetailData, setReportDetailData] = useState(null);
  const Ava = `/img/avat.png`;

  const [withdrawData, setWithdrawData] = useState([]);
  const modifiedResponse = allSymbols.map((item) => ({
    label: item.symbol,
    value: item.symbol,
  }));
  const fetchgetBlockUnblockWithdrawStatus = async () => {
    setIsLoading(true);
    try {
      const res = await getBlockUnblockWithdrawStatus();
      const resManualWithdraw = await getManulWithdrawStatus();
      setCurrentManualWithdrawStatus(resManualWithdraw?.data?.data?.status);
      setCurrentStatus(res?.data?.Data?.status);
      setIsLoading(false);
    } catch (error) {
      toast.error(error.response.data.Message);
      setIsLoading(false);
    }
  };
  const changeblockUnblockWithdraw = async () => {
    setIsLoading(true);
    try {
      const payload = {
        status:
          currentStatus === "inactive" ? "Active" : "inactive" || "Active",
        type: "withdraw",
      };
      const res = await blockUnblockWithdraw(payload);
      toast.success(res?.data?.Message);
      fetchgetBlockUnblockWithdrawStatus();
      setIsLoading(false);
    } catch (error) {
      console.log("🚀 ~ changeblockUnblockWithdraw ~ error:", error);
      setIsLoading(false);
    }
  };
  const changeManualWithdraw = async () => {
    setIsLoading(true);
    try {
      const payload = {
        status:
          currentManualWithdrawStatus === "inactive" ? "Active" : "inactive" || "Active",
        type: "withdraw",
      };
      const res = await manulWithdrawRequestUpdate(payload);
      console.log("🚀 ~ changeManualWithdraw ~ res:", res)
      toast.success(res?.data?.message);
      fetchgetBlockUnblockWithdrawStatus();
      setIsLoading(false);
    } catch (error) {
      console.log("🚀 ~ changeblockUnblockWithdraw ~ error:", error);
      setIsLoading(false);
    }
  };

  const fetchWithdrawLimit = async () => {
    try {
      const res = await getWithdrawLimit();
      setLimitVal(res?.data?.Data?.value);
    } catch (error) {
      console.log("🚀 ~ fetchWithdrawLimit ~ error:", error);
    }
  };
  useEffect(() => {
    fetchWithdrawLimit();
  }, [reFetch]);

  const fetchWithdrawData = async () => {
    setIsLoading(true);
    const payload = {
      page_number: 1,
      limit: 10000000,
      status: selectedOrderStatus,
      trx_type: searchActivity,
      email: searchEmail,
      user_name: searchName,
      symbol: searchSymbol
    };
    const res = await getWithdrawRequest(payload);
    setWithdrawData(res?.data.data);
    setIsLoading(false)

  };
  useEffect(() => {
    fetchWithdrawData();
    fetchgetBlockUnblockWithdrawStatus();
  }, [
    searchEmail,
    searchName,
    searchSymbol,
    searchActivity,
    selectedOrderStatus,
  ]);

  const header = [
    { accessor: "date", Header: "Date" },
    { accessor: "sender_name", Header: "Sender Name" },
    { accessor: "sender_email", Header: "Sender Email" },
    { accessor: "reciever_name", Header: "Receiver Name" },
    { accessor: "reciever_email", Header: "Receiver Email" },
    // { accessor: "trx_id", Header: "TRX ID" },
    // { accessor: "email", Header: "Email" },
    { accessor: "amount", Header: "Amount" },
    { accessor: "status", Header: "Status" },
    {
      accessor: "action",
      Header: "Action",
      disableGlobalFilter: true,
      width: 80,
    },
  ];
  useEffect(() => {
    const fetchData = async () => {
      try {
        const fees = await getToken();
        const data = fees?.data?.user;
        setAllSymbols(data);
      } catch (error) {
        console.error("Error fetching fees:", error);
      }
    };

    fetchData();
  }, []);
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  const handleApprove = async (item) => {
    console.log("🚀 ~ handleApprove ~ item:", item)
    if (item?.status !== "pending_withdraw") {
      toast.info(`Can not be approved beacasue status is ${item.status}`)
      return false
    }

    setIsLoading(true);
    try {
      const payload = {
        amount: item?.Amount,
        receiver: item?.Beneficiary,
        coin: item?.Symbol,
        record_id: item?._id,
        sender_id: item?.sender[0]?._id,
      };
      const res = await approveWithdraw(payload);
      console.log("🚀 ~ handleApprove ~ res:", res)
      toast.success(res?.data?.receipt);
      fetchWithdrawData();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error approving withdrawal:", error);
    }
  };

  const handleReject = async (val) => {
    console.log("🚀 ~ handleReject ~ val:", val)
    if (val?.status !== "pending_withdraw") {
      toast.info(`Can not be reject beacasue status is ${val.status}`)
      return false
    }
    setIsLoading(true);
    try {
      const res = await rejectWithdraw({ record_id: val._id });
      toast.success(res?.data?.message);
      fetchWithdrawData();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error rejecting withdrawal:", error);
    }
  };

  const handleViewClick = (item) => {
    setReportDetailData(item)
    setShowReportDetails(true)
  }

  const data = [];
  withdrawData?.map((item) =>
    data.push({
      sender_name: (
        <div className="flex flex-row pr-5 items-center  gap-3 ">
          <img
            className="flex h-[44px] w-[44px] rounded-3xl"
            src={item?.sender[0]?.image || Ava}
          />
          <h6 className="text-capitalize text-nowrap">
            {item?.sender[0]?.name}
          </h6>
        </div>
      ),
      sender_email: (
        <div className="flex gap-1 items-center">
          <h6 className="text-capitalize text-nowrap">
            {item?.sender[0]?.email}
          </h6>
          <button
            onClick={() => {
              navigator.clipboard.writeText(item?.sender[0]?.email);
              toast.success("Email copied to clipboard!");
            }}
            className="ml-2"
            style={{ color: "lightblue" }}
          >
            <FontAwesomeIcon icon={faCopy} />
          </button>
        </div>
      ),
      reciever_name: (
        <div className="flex flex-row pr-5 items-center  gap-3 ">
          <img
            className="flex h-[44px] w-[44px] rounded-3xl"
            src={item?.receiver[0]?.image || Ava}
          />
          <h6 className="text-capitalize text-nowrap">
            {item?.receiver[0]?.name}
          </h6>
        </div>
      ),
      reciever_email: (
        <div className="flex gap-1 items-center">
          <h6 className="text-capitalize text-nowrap">
            {item?.receiver[0]?.email}
          </h6>
          <button
            onClick={() => {
              navigator.clipboard.writeText(item?.receiver[0]?.email);
              toast.success("Email copied to clipboard!");
            }}
            className="ml-2"
            style={{ color: "lightblue" }}
          >
            <FontAwesomeIcon icon={faCopy} />
          </button>
        </div>
      ),
      status: (
        <h6
          className="text-capitalize flex-col"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {getStatusBadge(item.status)}
        </h6>
      ),
      trx_id: (
        <h6
          className="text-capitalize flex-col"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {item.trasaction_id}
        </h6>
      ),
      amount: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {`${numeral(item.Amount || 0).format("0,0.00000")} ${item.Symbol}`}
        </h6>
      ),
      date: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {moment(item.updatedAt).format("ll") || "--"}
        </h6>
      ),
      action:


        item.status === 'pending_withdraw' ?
          (
            <div className="flex flex-row gap-2">
              <i
                class="flex cursor-pointer fa fa-check text-success text-xl"
                onClick={() => handleApprove(item)}
                aria-hidden="true"
              ></i>
              <i
                class="flex cursor-pointer fa fa-times text-danger text-xl"
                onClick={() => handleReject(item)}
                aria-hidden="true"
              ></i>
              <td className="flex items-center justify-center">
                <img className="w-[20px] mt-2  self-center cursor-pointer" onClick={() => handleViewClick(item)} src="/img/eye.png" alt="Eye" />
              </td>
            </div>
          )
          :
          <td className="flex items-center justify-center">
            <img className="w-[20px] mt-2  self-center cursor-pointer" onClick={() => handleViewClick(item)} src="/img/eye.png" alt="Eye" />
          </td>



    })
  );

  const handleReset = () => {
    setSelectedOrderStatus("");
    setSearchName("");
    setSearchEmail("");
    setSearchActivity("virtual");
    setSearchSymbol("");
    setAllSymbols([]);
    setIsLoading(false);
    setCurrentStatus("");
    setCurrentManualWithdrawStatus("");
    setLimitVal("");
    setRefetch(false);
    setLimitType("");
    setModalLimit(false);
    setShowReportDetails(false);
    setReportDetailData(null);
  };



  return (
    <div className="flex flex-col gap-4 ">
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? "flex" : "none", // Use flex display when loading
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(255, 255, 255, 0.8)",
          zIndex: 999999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>
      <div className="flex col-12 flex-wrap">
        <Col xs={12} md={4} lg={3} hidden>
          <label htmlFor="features" className="form-label text-white mb-0">
            Search Filter
          </label>

        </Col>
        <Col xs={12} md={4} lg={3}>
          <InputDiv>
            <label
              style={{ fontSize: "small" }}
              htmlFor="features"
              className="form-label text-white mb-1"
            >
              Name
            </label>
            <ChatSearchInput
              type="text"
              value={searchName}
              className="form-control mt-3"
              placeholder="Search by name"
              onChange={(e) => {
                setSearchName(e.target.value);
              }}
            />
          </InputDiv>
        </Col>
        <Col xs={12} md={4} lg={3}>
          <InputDiv>
            <label
              style={{ fontSize: "small" }}
              htmlFor="features"
              className="form-label text-white mb-1"
            >
              Email
            </label>
            <ChatSearchInput
              type="text"
              value={searchEmail}
              className="form-control mt-3"
              placeholder="Search by Email"
              onChange={(e) => {
                setSearchEmail(e.target.value);
              }}
              id="File2"
              name="File2"
            />
          </InputDiv>
        </Col>
        <Col xs={12} md={4} lg={3}>
          <label
            style={{ fontSize: "small" }}
            htmlFor="features"
            className="form-label text-white mb-0 mt-2"
          >
            Symbol
          </label>
          <InputDiv className="px-0">
            <DropdownDiv
              className="mb-0"
              style={{ textTransform: "Capitalize" }}
            >
              <CustomSelect
                name="size"
                id="size"
                className="w-25 px-0"
                value={searchSymbol}
                onChange={(value) => {
                  setSearchSymbol(value.value);
                }}
                options={modifiedResponse}
              />
            </DropdownDiv>
          </InputDiv>
        </Col>
        <Col xs={12} md={4} lg={3}>
          <label
            style={{ fontSize: "small" }}
            htmlFor="features"
            className="form-label text-white mb-0 mt-2"
          >
            Status
          </label>
          <InputDiv className="px-0">
            <DropdownDiv
              className="mb-0"
              style={{ textTransform: "Capitalize" }}
            >
              <CustomSelect
                name="size"
                id="size"
                className="w-25 px-0"
                value={selectedOrderStatus}
                onChange={(value) => {
                  setSelectedOrderStatus(value.value);
                }}
                options={optionOrderStatus}
              />
            </DropdownDiv>
          </InputDiv>
        </Col>
        <Col xs={12} md={4} lg={3}>
          <label
            style={{ fontSize: "small" }}
            htmlFor="features"
            className="form-label text-white mb-0 mt-2"
          >
            Wallet activity
          </label>
          <InputDiv className="px-0">
            <DropdownDiv
              className="mb-0"
              style={{ textTransform: "Capitalize" }}
            >
              <CustomSelect
                name="size"
                id="size"
                className="w-25 px-0"
                value={searchActivity}
                onChange={(value) => {
                  setSearchActivity(value.value);
                }}
                options={historyOption}
              />
            </DropdownDiv>
          </InputDiv>
        </Col>
        <Col xs={12} md={4} lg={3}>
          <InputDiv>
            <FormButton
              variant="outline-danger"
              className="mt-4"
              type="button"
              onClick={handleReset}
            >
              Reset
            </FormButton>

          </InputDiv>
        </Col>

        <Col xs={12} md={4} lg={3}>
          <InputDiv>
            <Tooltip text={`${"Withdraw current is "} ${currentStatus}`}>
              <FormButton
                className="flex mt-4"
                variant="outline-primary"
                type="submit"
                onClick={changeblockUnblockWithdraw}
              >
                {currentStatus === "inactive" ? "Active" : "inactive"}
              </FormButton>
            </Tooltip>
          </InputDiv>
        </Col>
        <Col xs={12} md={4} lg={3}>
          <InputDiv>
            <Tooltip text={`${"Current Manual Withdraw is "} ${currentManualWithdrawStatus}`}>
              <FormButton
                className="flex mt-4"
                variant="outline-primary"
                type="submit"
                onClick={changeManualWithdraw}
              >
                {currentManualWithdrawStatus === "inactive" ? "Active Manual Withdraw" : "inactive Manual Withdraw"}
              </FormButton>
            </Tooltip>
          </InputDiv>
        </Col>

        <Col xs={12} md={4} lg={3}>
          <InputDiv>
            <FormButton
              className="flex mt-4"
              variant="outline-primary"
              type="submit"
              onClick={() => {
                setModalLimit(true);
                limitVal
                  ? setLimitType("editLimit ")
                  : setLimitType("setLimit");
              }}
            >
              {limitVal ? "Edit Withdraw Limit" : "Set withdraw Limit"}
            </FormButton>
          </InputDiv>
        </Col>
      </div>
      <div className="flex overflow-scroll mb-4">
        {withdrawData.length > 0 ? <ReactTableBase
          columns={header}
          data={data}
          tableConfig={tableConfig}
        /> : <h4>No data found</h4>}
      </div>

      <WithdrawReportDetailsModal show={showReportDetails} data={reportDetailData} hide={() => setShowReportDetails(false)} />

      {modalLimit && (
        <WithdrawLimitModal
          limitType={limitType}
          reFetch={() => setRefetch(!reFetch)}
          show={modalLimit}
          hide={() => setModalLimit(false)}
          data={limitVal}
        />
      )}
    </div>
  );
};
export default WithdrawReport;
const HashLink = styled.button`
  border: none;
  background: transparent;
  color: #4ce1b6;
`;

const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;

const DropdownDiv = styled.div`
  display: block;
  @media screen and (max-width: 876px) {
    width: 100%;
  }
`;

const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 1px solid #b1b1b1;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #b1b1b1;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
    border-bottom: 2px solid #47c9a4;
  }

  &::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  &::-moz-placeholder {
    color: #b1b1b1;
  }
  &:-moz-placeholder {
    color: #b1b1b1;
  }
  &:-ms-input-placeholder {
    color: #b1b1b1;
  }
`;
const FormButton = styled(Button)`
  color: "white";
  margin-top: 1rem;
`;
