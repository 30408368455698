import { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { Button } from "../../../shared/components/Button";
import { Col } from "react-bootstrap";
import { addService, getService } from "../../../utils/services/user.service";
import { toast } from "react-toastify";
import { Ring } from "@uiball/loaders";
import Services from "./AllServicesData";
import { useChainContext } from "../../context/context";

const AddServices = () => {
    const [bannerImage, setBannerImage] = useState(null);
    const [bannerPreview, setBannerPreview] = useState(null);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [isLoading, setIsLading] = useState(false);
    const [showForm, setShotForm] = useState(false);
    const [allServices, setAllServices] = useState([]);
    const { reFethData, setReFetchData } = useChainContext();
    const fileInputRef = useRef(null);

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            setBannerImage(file);
            const blobUrl = URL.createObjectURL(file);
            setBannerPreview(blobUrl);
        }
    };

    const handleSubmit = async () => {
        try {
            if (!bannerImage || !title || !description) {
                throw new Error("All fields are required!");
            }
            setIsLading(true)

            const formData = new FormData();
            formData.append("File", bannerImage);
            formData.append("title", title);
            formData.append("description", description);
            setShotForm(false)

            const res = await addService(formData);
            toast.success(res?.data?.Message);
            setIsLading(false)
            fetchAllServives()

        } catch (error) {
            console.error("Error submitting form:", error);
            setIsLading(false)
            toast.error(error.message || "An error occurred while submitting.");
        }
    };

    const fetchAllServives = async () => {
        setIsLading(true)

        const res = await getService()
        console.log("🚀 ~ fetchAllServives ~ res:", res)
        setAllServices(res?.data?.data)
        setIsLading(false)
    }

    useEffect(() => {
        fetchAllServives()
    }, [reFethData])

    return (
        <div className="w-full">
            <div
                className="loader-overlay"
                style={{
                    display: isLoading ? "flex" : "none", // Use flex display when loading
                    justifyContent: "center", // Center horizontally
                    alignItems: "center", // Center vertically
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: "rgba(255, 255, 255, 0.8)",
                    zIndex: 999999,
                }}
            >
                <Ring size={65} color="#00897B" />
            </div>
            {showForm &&
                <>

                    <h2 className="text-xl font-bold mb-4">Add New Service</h2>

                    {bannerPreview && (
                        <img
                            src={bannerPreview}
                            alt="Service Banner"
                            className="banner-preview md:w-[30%] mb-4 w-full h-auto rounded-lg"
                        />
                    )}

                    <div className="flex flex-row">

                        <Col xs={12} md={4} lg={3}>
                            <InputDiv>
                                <label
                                    style={{ fontSize: "small" }}
                                    htmlFor="features"
                                    className="form-label text-white mb-1"
                                >
                                    Upload Service Banner
                                </label>
                                <Button variant="outline-primary" onClick={handleButtonClick}>
                                    Upload Service Banner
                                </Button>
                                <input
                                    type="file"
                                    accept="image/*"
                                    ref={fileInputRef}
                                    hidden
                                    onChange={handleImageUpload}
                                />
                            </InputDiv>
                        </Col>

                        <Col xs={12} md={4} lg={3}>
                            <InputDiv>
                                <label
                                    style={{ fontSize: "small" }}
                                    htmlFor="features"
                                    className="form-label text-white mb-4"
                                >
                                    Service Title
                                </label>
                                <ChatSearchInput
                                    type="text"
                                    value={title}
                                    placeholder="Enter Service Title"
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                            </InputDiv>
                        </Col>

                        <Col xs={12} md={4} lg={3}>
                            <InputDiv>
                                <label
                                    style={{ fontSize: "small" }}
                                    htmlFor="features"
                                    className="form-label text-white mb-1"
                                >
                                    Description
                                </label>
                                <ChatSearchInput
                                    as="textarea"
                                    value={description}
                                    placeholder="Enter Service Description"
                                    onChange={(e) => setDescription(e.target.value)}
                                    className="resize-vertical"
                                />
                            </InputDiv>
                        </Col>
                        <div className="text-center mt-6">
                            <Button variant="primary" onClick={handleSubmit}>
                                Add Service
                            </Button>
                        </div>


                    </div>
                </>
            }

            {!showForm && <div className="text-center mt-6">
                <Button variant="primary" onClick={() => setShotForm(true)}>
                    Add Service
                </Button>
            </div>}

            <Services data={allServices} />
        </div>
    );
};

export default AddServices;

const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 12px;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 1px solid #b1b1b1;
  color: #b1b1b1;

  &:focus {
    outline: none;
    border-bottom: 2px solid #47c9a4;
  }

  &::placeholder {
    color: #b1b1b1;
  }
`;
const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;